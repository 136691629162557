import React from 'react'
import './Footer.css'
export default function Header() {
  return (
    <div>
      <p> We have done our best to provide information as accurate as possible. There could be some discrepancies. If you encouter an issue please report to us</p>
      <p>Brightstake Ⓒ 2022</p>
    </div>
  )
}
